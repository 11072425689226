<template>
  <div class="color-score" :class="{ 'color-gradient': scoreVal }">
    <img src="~@/assets/img/score_sectors.png" alt="" class="z-index-8" />

    <!-- <div v-if="scoreVal" class="score-pointer font-weight-bold" :style="{left: ptjScoreVal}"> -->
    <div
      v-if="scoreVal"
      class="score-pointer font-weight-bold z-index-7"
      :style="{ transform: rotateScoreVal }"
    >
      <img src="~@/assets/img/score_area.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ColorScore",
  props: ["score"],
  computed: {
    scoreVal() {
      return (this.score >= 0 && (this.score * 10).toFixed(1)) || false;
    },
    ptjScoreVal() {
      return `calc(${(this.score * 100).toFixed(0)}%)`;
    },
    rotateScoreVal() {
      return `rotate(${this.score * 180 - 90}deg)`;
    }
  }
};
</script>

<style lang="scss">
.color-score {
  position: relative;
  filter: drop-shadow(0 3px 15px #ffffff55);
  display: flex;
  justify-content: center;

  &:not(.color-gradient) {
    filter: saturate(0) blur(2px);
  }
}
.score-pointer {
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 10%;
  display: flex;
  justify-content: center;

  img {
    position: absolute;
    bottom: 0;
  }
}

.suggestions .color-score img {
  max-height: 25px;
}
</style>
