var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Resultado: "+_vm._s(_vm.editedItem.result))]),_c('ul',_vm._l((_vm.drugResultSources),function(drugResultSource){return _c('li',{key:drugResultSource.key},[_c('strong',[_vm._v(_vm._s(drugResultSource.key))]),_vm._v(": "),_c('v-icon',{attrs:{"small":"","color":drugResultSource.result}},[_vm._v(" mdi-circle ")])],1)}),0),_c('hr'),_c('ColorScore',{attrs:{"score":parseFloat(_vm.editedItem.result)}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-spacer')],1)],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.$router.push({
            name: 'SampleGXSnpsResults',
            params: { sampleId: _vm.sampleId }
          })}}},[_vm._v(" SNPs ")]),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'SampleGXGenesResults',
            params: { sampleId: _vm.sampleId }
          })}}},[_vm._v(" Genes ")])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.searchableFields,"item-text":"text","item-value":"value","label":"Campos"},model:{value:(_vm.activeFilter),callback:function ($$v) {_vm.activeFilter=$$v},expression:"activeFilter"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filtrar"},model:{value:(_vm.filterSearch),callback:function ($$v) {_vm.filterSearch=$$v},expression:"filterSearch"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"items":_vm.allFields,"label":"Columnas","multiple":"","chips":""},model:{value:(_vm.selectedFields),callback:function ($$v) {_vm.selectedFields=$$v},expression:"selectedFields"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.selectedHeaders,"items":_vm.sampleGXResults,"options":_vm.options,"multi-sort":"","server-items-length":_vm.totalSampleGXResults,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.result",fn:function(ref){
          var item = ref.item;
return [_c('ColorScore',{attrs:{"score":parseFloat(item.result)}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-eye ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }