<template>
  <v-card>
    <v-card-title>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Resultado: {{ editedItem.result }}</v-card-title
          >
          <ul>
            <li
              v-for="drugResultSource in drugResultSources"
              :key="drugResultSource.key"
            >
              <strong>{{ drugResultSource.key }}</strong
              >:
              <v-icon small :color="drugResultSource.result">
                mdi-circle
              </v-icon>
            </li>
          </ul>
          <hr />
          <ColorScore :score="parseFloat(editedItem.result)" />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-spacer></v-spacer>
      <v-col cols="4">
        <v-btn
          @click="
            $router.push({
              name: 'SampleGXSnpsResults',
              params: { sampleId: sampleId }
            })
          "
          depressed
        >
          SNPs
        </v-btn>
        <v-btn
          @click="
            $router.push({
              name: 'SampleGXGenesResults',
              params: { sampleId: sampleId }
            })
          "
          depressed
          color="primary"
        >
          Genes
        </v-btn>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="searchableFields"
          v-model="activeFilter"
          item-text="text"
          item-value="value"
          label="Campos"
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="filterSearch"
          append-icon="mdi-magnify"
          label="Filtrar"
        ></v-text-field>
      </v-col>
    </v-card-title>
    <v-row>
      <v-col cols="12">
        <v-combobox
          v-model="selectedFields"
          :items="allFields"
          label="Columnas"
          multiple
          chips
        ></v-combobox>
      </v-col>
    </v-row>
    <v-data-table
      :headers="selectedHeaders"
      :items="sampleGXResults"
      :options.sync="options"
      multi-sort
      :server-items-length="totalSampleGXResults"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:item.result="{ item }">
        <ColorScore :score="parseFloat(item.result)" />
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import Strapi from "@/services/Strapi.js";
import ColorScore from "@/components/mylogy/ColorScore.vue";
export default {
  props: ["sampleId"],
  components: { ColorScore },
  data() {
    return {
      totalSampleGXResults: 0,
      sampleGXResults: [],
      filterSearch: null,
      loading: true,
      activeFilter: "drug",
      options: {},
      editedIndex: -1,
      dialog: false,
      selectedFields: ["Medicamento", "Acciones", "Resultado"],
      editedItem: { source: {} },
      headers: [
        {
          text: "Medicamento",
          align: "start",
          sortable: true,
          searchable: true,
          value: "drug"
        },
        {
          text: "Resultado",
          sortable: true,
          searchable: false,
          value: "result"
        },

        { text: "Acciones", value: "actions", sortable: false }
      ]
    };
  },
  computed: {
    drugResultSources() {
      return Object.entries(this.editedItem.source).map(e => {
        return {
          key: e[0],
          result: e[1]
        };
      });
    },
    searchableFields() {
      return this.selectedHeaders.filter(h => h.searchable);
    },
    allFields() {
      return this.headers.map(h => h.text);
    },
    selectedHeaders() {
      return this.headers.filter(h => {
        return this.selectedFields.includes(h.text);
      });
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },
    filterSearch: {
      handler() {
        this.getDataFromApi();
      }
    },
    dialog(val) {
      val || this.close();
    }
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.sampleGXResults.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const _sort = sortBy
        .map(function(e, i) {
          return `${e}:${(sortDesc[i] && "DESC") || "ASC"}`;
        })
        .join(",");
      const qs = require("qs");

      let params = {
        _start: (page - 1) * itemsPerPage,
        _limit: itemsPerPage,
        _where: {
          sample: this.sampleId
        }
      };

      if (sortBy.length > 0) {
        params._sort = _sort;
      }

      if (this.filterSearch) {
        params._where = {
          _or: [
            { FIELD: this.filterSearch, sample: this.sampleId },
            { FIELD_contains: this.filterSearch, sample: this.sampleId }
          ]
        };
      }

      const query = qs
        .stringify(params, {
          encodeValuesOnly: true
        })
        .replace(/FIELD/g, this.activeFilter);

      Strapi.get(`/pgx-drug-results?${query}`).then(res => {
        this.sampleGXResults = res.data;
        Strapi.get(`/pgx-drug-results/count?${query}`).then(resCount => {
          this.totalSampleGXResults = resCount.data;
          this.loading = false;
        });
      });
    }
  }
};
</script>
<style lang="css">
tr {
  height: 70px !important;
}
</style>
